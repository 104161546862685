//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Card'
};
