//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'AnimatedBackground',
  data() {
    return {
      showTransitionLayer: false,
      transitionColor: this.background,
      baseColor: this.background
    };
  },
  computed: mapState(['background']),
  watch: {
    background() {
      this.changeColor(this.background);
    },
    $route(newRoute, oldRoute) {
      if (newRoute.name !== oldRoute.name && this.$refs.scroll) {
        this.$refs.scroll.scrollTop = 0;
      }
    }
  },
  methods: {
    changeColor(background) {
      if (background === 'transparent') {
        this.transitionColor = this.baseColor;
        this.baseColor = 'transparent';
        this.showTransitionLayer = false;
      } else {
        this.baseColor = this.transitionColor;
        this.transitionColor = background;
        this.showTransitionLayer = false;
        this.$nextTick(() => (this.showTransitionLayer = true));
      }
    },
    afterEntered() {
      this.baseColor = this.transitionColor;
    },
    afterLeft() {
      this.transitionColor = 'transparent';
      this.showTransitionLayer = true;
    }
  }
};
