import Vue from 'vue';
import { Record, Id } from '@/models';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '~/store';
import { parseScaledImageFromFirestore } from './resized-image';

export interface RecordState<T extends Record> {
  records: { [recordId: string]: T };
  loading: boolean;
  listener: (() => void) | null;
}

export function recordState<T extends Record>(): RecordState<T> {
  return {
    records: {},
    loading: false,
    listener: null
  };
}

export function recordMutations<T extends Record>(): MutationTree<RecordState<T>> {
  return {
    setRecord(state, { id, record }: { id: string; record: T }) {
      Vue.set(state.records, id, record);
    },
    clear(state) {
      state.records = {};
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setListener(state, listener: () => void) {
      if (state.listener !== null) {
        state.listener();
      }
      state.listener = listener;
    }
  };
}

export function recordGetters<T extends Record>(): GetterTree<RecordState<T>, RootState> {
  return {
    recordList(state): Id<T>[] {
      return Object.keys(state.records).map((id) => ({ id, ...state.records[id] }));
    }
  };
}

export function recordActions<T extends Record>(collection: string, scaledImages: string[]): ActionTree<RecordState<T>, RootState> {
  return {
    async fetch(store) {
      if (store.state.listener !== null) {
        return;
      }

      store.commit('setLoading', true);
      await new Promise((resolve) => {
        const listener = this.$fire.firestore
          .collection(`/centers/${(store.rootState as any).centers.centerId}/${collection}`)
          .onSnapshot((snapshot) => {
            if (snapshot.docs.length === 0) {
              store.commit('clear');
            }

            const docs = snapshot.docs.map(async (record) => {
              const complete: T = record.data() as T;
              let updateScaled = false;
              for (const scaledImage of scaledImages) {
                const newScaled = await parseScaledImageFromFirestore(this.$fire.storage, complete[scaledImage]);
                if (newScaled) {
                  updateScaled = true;
                  complete[scaledImage] = newScaled;
                }
              }

              if (updateScaled) {
                await store.dispatch('update', { id: record.id, record: complete });
              } else {
                store.commit('setRecord', { id: record.id, record: complete });
              }
            });

            Promise.all(docs).then(() => {
              store.commit('setLoading', false);
              resolve();
            });
          });
        store.commit('setListener', listener);
      });
    },
    async create(store, record: T): Promise<Id<T>> {
      const doc = await this.$fire.firestore
        .collection(`/centers/${(store.rootState as any).centers.centerId}/${collection}`)
        .add(record);
      store.commit('setRecord', { id: doc.id, record });
      return { id: doc.id, ...record };
    },
    async update(store, { id, record }: { id: string; record: T }): Promise<Id<T>> {
      await this.$fire.firestore
        .collection(`/centers/${(store.rootState as any).centers.centerId}/${collection}`)
        .doc(id)
        .set(record);
      store.commit('setRecord', { id, record });
      return { id, ...record };
    }
  };
}
