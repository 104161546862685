import firebase from 'firebase/app';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import { Id } from '@/models';
import { Child, ChildDocument } from '@/models/children';
import { RecordState, recordState, recordMutations, recordGetters, recordActions } from '~/util/vuex-records';

interface State extends RecordState<Child> {
  listener: (() => void) | null;
}

export const state = (): State => ({
  ...recordState()
});

export const mutations: MutationTree<State> = {
  ...recordMutations()
};

export const getters: GetterTree<State, RootState> = {
  ...recordGetters(),
  attendingChildren(state): Id<Child>[] {
    return Object.keys(state.records)
      .map((id) => ({ id, ...state.records[id] }))
      .filter((c) => c.endDate === null);
  },
  noLongerAttendingChildren(state): Id<Child>[] {
    return Object.keys(state.records)
      .map((id) => ({ id, ...state.records[id] }))
      .filter((c) => c.endDate !== null);
  }
};

export const actions: ActionTree<State, RootState> = {
  ...recordActions('children', ['profilePhoto']),
  async updateBasicMedical(store, { id, allergies, conditions }) {
    const providerId = (store.rootState as any).centers.centerId;

    await this.$fire.firestore.collection(`/centers/${providerId}/children`).doc(id).update({ allergies, conditions });
  },
  async uploadChildImage(store, { childId, imageFile }: { childId: string; imageFile: File }) {
    const extension = imageFile.name.split('.')[imageFile.name.split('.').length - 1];
    const timestamp = Date.now().toString();

    const child = store.state.records[childId];
    if (!child) {
      return;
    }

    await this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers.centerId}/children`)
      .doc(childId)
      .update({ 'profilePhoto.timestamp': timestamp });

    const imageRef = this.$fire.storage
      .ref()
      .child('centers')
      .child((store.rootState as any).centers.centerId)
      .child('children')
      .child(childId)
      .child('profile-photo.' + extension);
    await imageRef.put(imageFile, {
      contentType: imageFile.type,
      cacheControl: 'max-age=604800',
      customMetadata: {
        centerId: (store.rootState as any).centers.centerId!,
        childId,
        timestamp
      }
    });

    child.profilePhoto.full = await imageRef.getDownloadURL();
    store.commit('setRecord', { id: childId, record: child });
  },
  async deleteChildImage(store, { childId }: { childId: string }) {
    const child = store.state.records[childId];
    if (!child) {
      return;
    }

    const deleteJobs: Promise<any>[] = [];

    if (child.profilePhoto.full) {
      deleteJobs.push(this.$fire.storage.refFromURL(child.profilePhoto.full).delete());
    }

    if (child.profilePhoto.small) {
      deleteJobs.push(this.$fire.storage.refFromURL(child.profilePhoto.small).delete());
    }

    if (child.profilePhoto.large) {
      deleteJobs.push(this.$fire.storage.refFromURL(child.profilePhoto.large).delete());
    }

    child.profilePhoto.full = null;
    child.profilePhoto.large = null;
    child.profilePhoto.small = null;
    deleteJobs.push(store.dispatch('update', { id: childId, record: child }));

    await Promise.all(deleteJobs);
  },
  async fetchChildDocuments(store, childId): Promise<ChildDocument[]> {
    const results = await this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers.centerId}/children/${childId}/medical-documents`)
      .get();
    const docsPromise = results.docs.map((doc) => ({
      ...(doc.data() as ChildDocument),
      id: doc.id
    }));

    const docs: ChildDocument[] = await Promise.all(docsPromise);
    for (const doc of docs) {
      doc.url = await this.$fire.storage.refFromURL(doc.path).getDownloadURL();
    }
    return docs.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  },
  uploadChildDocument(
    store,
    data: { childId: string; documentFile: File; documentName: string }
  ): { id: string; task: firebase.storage.UploadTask } {
    const { childId, documentFile, documentName } = data;
    const timestamp = Date.now().toString();
    const fileName = timestamp + '-' + documentFile.name;

    const firestoreRef = this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers.centerId}/children/${childId}/medical-documents`)
      .doc();

    const fileRef = this.$fire.storage
      .ref()
      .child('centers')
      .child((store.rootState as any).centers.centerId)
      .child('children')
      .child(childId)
      .child('medical-documents')
      .child(fileName);

    const task = fileRef.put(documentFile, {
      contentType: documentFile.type,
      cacheControl: 'max-age=600',
      customMetadata: {
        centerId: (store.rootState as any).centers.centerId,
        childId
      }
    });

    task.then((snapshot) => {
      if (snapshot.state === firebase.storage.TaskState.SUCCESS)
        firestoreRef.set({
          path: fileRef.toString(),
          name: documentName
        });
    });

    return { id: firestoreRef.id, task };
  },
  async updateChildDocument(store, { childId, documentId, name }) {
    await this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers.centerId}/children/${childId}/medical-documents`)
      .doc(documentId)
      .update({ name });
  },
  async deleteChildDocument(store, { childId, documentId }) {
    const doc = (
      await this.$fire.firestore
        .collection(`/centers/${(store.rootState as any).centers.centerId}/children/${childId}/medical-documents`)
        .doc(documentId)
        .get()
    ).data() as ChildDocument;

    await this.$fire.storage.refFromURL(doc.path).delete();
    await this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers.centerId}/children/${childId}/medical-documents`)
      .doc(documentId)
      .delete();
  }
};
