import { Center } from '@/models/centers';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import { Id } from '@/models';
import firebase from 'firebase/app';

interface State {
  centerId: string | null;
  centers: Id<Center>[];
}

export const state = (): State => ({
  centerId: null,
  centers: []
});

export const mutations: MutationTree<State> = {
  setCenters(state, centers: Id<Center>[]) {
    state.centers = centers;
  },
  setCenterId(state, centerId: string | null) {
    state.centerId = centerId;
  },
  clear(state) {
    state.centerId = null;
    state.centers = [];
  }
};

export const getters: GetterTree<State, RootState> = {
  center(state) {
    if (state.centerId) {
      return state.centers.filter((c) => c.id === state.centerId)[0] || null;
    } else {
      return null;
    }
  }
};

export const actions: ActionTree<State, RootState> = {
  async updateCenters(store) {
    const centers = await this.$fire.firestore
      .collection('/centers')
      .where('employees', 'array-contains', store.rootState.userId)
      .get();
    const docs: Id<Center>[] = centers.docs.map((doc) => ({ id: doc.id, ...(doc.data() as Center) }));
    store.commit('setCenters', docs);
    if (docs.length > 0 && store.state.centerId === null) {
      store.commit('setCenterId', docs[0].id);
    }
  },
  async updateCenter(store, { id, name, phoneNumber, city, street, state, zip, licenseNumber, county }: Id<Center>): Promise<string> {
    await this.$fire.firestore
      .collection('/centers')
      .doc(id)
      .update({ name, phoneNumber, city, street, state, zip, licenseNumber, county });
    await store.dispatch('updateCenters');
    return id;
  },
  async uploadCenterImage(store, { centerId, imageFile }: { centerId: string; imageFile: File }) {
    const extension = imageFile.name.split('.')[imageFile.name.split('.').length - 1];

    const imageRef = this.$fire.storage
      .ref()
      .child('centers')
      .child(store.state.centerId!)
      .child('logo.' + extension);
    await imageRef.put(imageFile, {
      contentType: imageFile.type,
      cacheControl: 'max-age=604800'
    });

    const logo = await imageRef.getDownloadURL();

    await this.$fire.firestore.collection('/centers').doc(centerId).update({ logo });
    await store.dispatch('updateCenters');
  },
  async removeLogo(store, url: string) {
    const ref = this.$fire.storage.refFromURL(url);
    await ref.delete();

    await this.$fire.firestore
      .collection('/centers')
      .doc(store.state.centerId!)
      .update({ logo: firebase.firestore.FieldValue.delete() });
    await store.dispatch('updateCenters');
  }
};
