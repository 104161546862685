










import SideNav from '~/components/SideNav.vue';
import BottomNav from '~/components/BottomNav.vue';

export default {
  components: {
    SideNav,
    BottomNav
  }
};
