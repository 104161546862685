import { Context } from '@nuxt/types';
import { ScalableImage, Person } from '@/models';

function formatMoney(amount: number | null | undefined, withDollarsign: boolean = false) {
  const dollarSign = withDollarsign ? '$' : '';

  if (amount) {
    if (amount >= 0) {
      return dollarSign + (amount / 100).toFixed(2);
    } else {
      return '-' + dollarSign + ((amount * -1) / 100).toFixed(2);
    }
  } else {
    return '0.00';
  }
}

function formatMoneyFancy(amount: number | null | undefined) {
  if (amount) {
    const raw = (amount / 100).toFixed(2);
    const dollars: String = raw.split('.')[0];
    const cents = raw.split('.')[1];

    let formatted: String | null = null;
    let position = 0;
    while (position < dollars.length) {
      let newPosition = position + 3;
      if (newPosition > dollars.length) {
        newPosition = dollars.length;
      }

      const section = dollars.substring(dollars.length - (position + 3), dollars.length - position);

      if (formatted === null) {
        formatted = section;
      } else {
        formatted = section + ',' + formatted;
      }

      position = newPosition;
    }

    return formatted + '.' + cents;
  } else {
    return '0.00';
  }
}

function formatDate(isoDate: string | null | undefined) {
  const date = new Date(isoDate + 'T01:00:00');
  const month = 1 + date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  return ('00' + month).slice(-2) + '/' + ('00' + day).slice(-2) + '/' + year.toString().slice(-2);
}

function todayISO() {
  return new Date().toISOString().split('T')[0];
}

function fullImage(imageData: ScalableImage | undefined | null): string | null {
  if (!imageData || !imageData.full) {
    return null;
  }

  return imageData.full;
}

function smallImage(imageData: ScalableImage | undefined | null): string | null {
  if (!imageData || !imageData.full) {
    return null;
  }

  if (!imageData.small) {
    return imageData.full;
  }

  return imageData.small;
}

function largeImage(imageData: ScalableImage | undefined | null): string | null {
  if (!imageData || !imageData.full) {
    return null;
  }

  if (!imageData.large) {
    return imageData.full;
  }

  return imageData.large;
}

function name(person: Person | undefined): string {
  if (!person) {
    return '';
  } else if (person.firstName && person.lastName) {
    return person.firstName + ' ' + person.lastName;
  } else if (person.firstName) {
    return person.firstName;
  } else if (person.lastName) {
    return person.lastName;
  } else {
    return '';
  }
}

function formatPhone(phoneNumber: string): string {
  return '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6);
}

export default (_: Context, inject: (name: string, value: any) => void) => {
  inject('formatMoney', formatMoney);
  inject('formatMoneyFancy', formatMoneyFancy);
  inject('formatDate', formatDate);
  inject('todayISO', todayISO);
  inject('fullImage', fullImage);
  inject('smallImage', smallImage);
  inject('largeImage', largeImage);
  inject('name', name);
  inject('formatPhone', formatPhone);
};
