import { render, staticRenderFns } from "./ColorButton.vue?vue&type=template&id=e9f7cdba&scoped=true&"
import script from "./ColorButton.vue?vue&type=script&lang=ts&"
export * from "./ColorButton.vue?vue&type=script&lang=ts&"
import style0 from "./ColorButton.vue?vue&type=style&index=0&id=e9f7cdba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9f7cdba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WhiteSpinner: require('/builds/sama-apps/rainbow-care/rainbow-care-web/components/WhiteSpinner.vue').default})
