








































































































import { defineComponent, computed, ref, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SideNav',
  setup() {
    const { store } = useContext();
    const selected = computed(() => store.state.currentNav);
    const installApp = computed<any | null>(() => store.state.installApplication);
    const updateApp = ref<boolean>(false);

    const workbox = (window as any).$workbox;
    if (workbox) {
      workbox.then((sw) => {
        sw.addEventListener('installed', (event) => {
          if (event.isUpdate) {
            updateApp.value = true;
          }
        });
      });
    }

    async function signOut() {
      await store.dispatch('logout');
    }

    async function install() {
      if (installApp.value !== null) {
        await installApp.value.prompt();
        store.commit('registerInstallApplication', null);
      }
    }

    function update() {
      location.reload();
    }

    return {
      selected,
      signOut,
      installApp,
      install,
      updateApp,
      update
    };
  }
});
