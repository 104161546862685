









































import { defineComponent, ref, onMounted, onBeforeUnmount, computed, watch, useContext } from '@nuxtjs/composition-api';
import { Ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { store, route } = useContext();
    const select = computed<string | null>(() => store.state.lastNav);

    const buttons = ref<HTMLElement | null>(null);
    const left = ref('');
    const right = ref('');

    const home = ref<HTMLElement | null>(null);
    const children = ref<HTMLElement | null>(null);
    const activities = ref<HTMLElement | null>(null);
    const income = ref<HTMLElement | null>(null);
    const expenses = ref<HTMLElement | null>(null);

    const refs = {
      home,
      children,
      activities,
      income,
      expenses
    };

    function resize() {
      const ele = refs[select.value || ''] as Ref<HTMLElement | null>;
      if (!ele) {
        left.value = '0';
        right.value = '100%';
        return;
      }
      const el: HTMLElement | null = ele.value;
      if (el === null) return;

      const middle = el.offsetLeft - buttons.value!.offsetLeft + el.offsetWidth / 2;
      const length = el.offsetWidth / 2 + 5;

      left.value = middle - length + 'px';
      right.value = 'calc(100% - ' + (middle + length) + 'px)';
    }

    onMounted(() => {
      resize();
      window.addEventListener('resize', resize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize);
    });

    watch(route, () => {
      if (store.state.currentNav === store.state.lastNav) return;
      store.commit('setLastNav', store.state.currentNav);
      resize();
    });

    return {
      buttons,
      left,
      right,
      select,
      resize,
      home,
      children,
      activities,
      income,
      expenses
    };
  }
});
