import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { UserInfo } from '@/models/userinfo';

// export const strict = false;

export interface RootState {
  authenticated: boolean;
  userId: string | null;
  userEmail: string | null;

  userInfo: UserInfo | null;

  background: string;
  lastNav: string | null;
  currentNav: string;

  installApplication: any;
}

export const state = (): RootState => ({
  authenticated: false,
  userId: null,
  userEmail: null,

  userInfo: null,

  background: 'transparent',
  lastNav: null,
  currentNav: 'home',

  installApplication: null
});

export const mutations: MutationTree<RootState> = {
  setAuthenticated: (state, isAuthenticated) => (state.authenticated = isAuthenticated),
  setUserId: (state, userId) => (state.userId = userId),
  setUserEmail: (state, email) => (state.userEmail = email),

  setUserInfo: (state, userInfo: UserInfo | null) => (state.userInfo = userInfo),

  setBackground: (state, newColor) => (state.background = newColor),
  setLastNav: (state, lastNav) => (state.lastNav = lastNav),
  setCurrentNav: (state, currentNav) => (state.currentNav = currentNav),

  registerInstallApplication: (state, installApplication) => (state.installApplication = installApplication),

  clear(state) {
    state.userId = null;
    state.userEmail = null;
    state.background = 'transparent';
    state.userInfo = null;

    state.lastNav = null;
    state.currentNav = 'home';
  }
};

export const getters: GetterTree<RootState, RootState> = {};

export const actions: ActionTree<RootState, RootState> = {
  async onAuthStateChanged(store, { authUser }) {
    if (authUser) {
      const loggedIn = !store.state.authenticated;
      store.commit('setUserId', authUser.uid);
      store.commit('setUserEmail', authUser.email);
      store.commit('setAuthenticated', true);
      await store.dispatch('updateUserData');
      if (loggedIn) {
        this.$router.push('/home');
      }
    } else {
      const loggedOut = store.state.authenticated;
      store.commit('setAuthenticated', false);
      if (loggedOut) {
        this.$router.push('/login', () => store.dispatch('clearAll'));
      } else {
        store.dispatch('clearAll');
      }
    }
  },
  async updateUserData(store) {
    const userInfo: UserInfo = (
      await this.$fire.firestore.collection('users').doc(this.$fire.auth.currentUser!.uid).get()
    ).data() as UserInfo;
    store.commit('setUserInfo', userInfo);
  },
  async login(_, { email, password }: { email: string; password: string }) {
    await this.$fire.auth.signInWithEmailAndPassword(email, password);
  },
  async logout() {
    await this.$fire.auth.signOut();
  },
  clearAll(store) {
    store.commit('setUserId', null);
    store.commit('setUserEmail', null);
    store.commit('setUserInfo', null);

    store.commit('children/setListener', null);
    store.commit('children/clear');
    store.commit('parents/setListener', null);
    store.commit('parents/clear');
    store.commit('income/setListener', null);
    store.commit('income/clear');
    store.commit('expenses/setListener', null);
    store.commit('expenses/clear');
    store.commit('activities/setListener', null);
    store.commit('activities/clear');
    store.commit('centers/clear');
    store.commit('clear');
  },
  async registerProvider(store, { email, password, firstName, lastName, centerData }) {
    await this.$fire.auth.createUserWithEmailAndPassword(email, password);
    if (this.$fire.auth.currentUser === null) throw new Error('Current user was null despite creation');

    const userInfo: UserInfo = {
      firstName,
      lastName,
      pushTokens: {}
    };

    await this.$fire.firestore.collection('users').doc(this.$fire.auth.currentUser.uid).set(userInfo);
    await this.$fire.functions.httpsCallable('createCenter')(centerData);
    await store.dispatch('centers/updateCenters');
  }
};
