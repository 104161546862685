








import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import WhiteSpinner from '~/components/WhiteSpinner.vue';

export default defineComponent({
  components: {
    WhiteSpinner
  },
  props: {
    label: String,
    shadow: Boolean,
    small: Boolean,
    accent: Boolean,
    loads: Boolean,
    theme: String,
    fitContent: Boolean
  },
  setup(props, context) {
    const button = ref(null);
    const loading = ref(false);

    const style = computed(() => {
      let color = props.accent ? '--accent-color' : '--theme-color';

      if (props.theme) {
        color = props.theme;
      }

      if (!button.value) {
        return {
          '--main-color': color
        };
      }

      if (color.startsWith('--')) {
        color = 'var(' + color + ')';
      }

      return {
        '--main-color': color,
        '--hover-color': context.root.$calculateSecondaryColor(color, button.value)
      };
    });

    const clicked = () => {
      if (props.loads) {
        if (loading.value) return;
        const finished = new Promise((resolve) => {
          loading.value = true;
          context.emit('click', resolve);
        });

        finished.then(() => {
          loading.value = false;
        });
      } else {
        context.emit('click');
      }
    };

    return {
      button,
      clicked,
      style,
      loading
    };
  }
});
