//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DataPage',
  props: {
    label: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      required: true
    },
    secondaryColor: {
      type: String,
      required: true
    },
    accentColor: {
      type: String,
      required: true
    },
    empty: Boolean,
    callToAction: {
      type: String,
      required: true
    },
    emptyLabel: {
      type: String,
      required: true
    },
    emptyImage: {
      type: String,
      required: true
    },
    itemLabel: {
      type: String,
      default: null
    },
    buttonLabel: {
      type: String,
      default: null
    },
    headerHeight: {
      type: Number,
      default: null
    },
    blueHeader: Boolean
  }
};
