import { render, staticRenderFns } from "./error.vue?vue&type=template&id=47729d3c&scoped=true&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47729d3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DataPage: require('/builds/sama-apps/rainbow-care/rainbow-care-web/components/DataPage.vue').default})
