import { Activity } from '@/models/activities';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import { RecordState, recordState, recordMutations, recordGetters, recordActions } from '~/util/vuex-records';

interface State extends RecordState<Activity> {}

export const state = (): State => ({
  ...recordState()
});

export const mutations: MutationTree<State> = {
  ...recordMutations()
};

export const getters: GetterTree<State, RootState> = {
  ...recordGetters(),
  activityAnnualTotal(state): number {
    const year = new Date().getFullYear();
    return Object.values(state.records)
      .filter(i => i.date.startsWith(year.toString()))
      .map(i => i.duration)
      .reduce((a, b) => a + b, 0);
  },
  activityTotal(state): number {
    return Object.values(state.records)
      .map(i => i.duration)
      .reduce((a, b) => a + b, 0);
  }
};

export const actions: ActionTree<State, RootState> = {
  ...recordActions('activities', [])
};
