import firebase from 'firebase';
import { ScalableImage } from '@/models';

export async function parseScaledImageFromFirestore(
  storage: firebase.storage.Storage,
  imageData: ScalableImage
): Promise<ScalableImage | null> {
  let updated = false;
  if (imageData.full && imageData.full.startsWith('gs://')) {
    try {
      imageData.full = await storage.refFromURL(imageData.full).getDownloadURL();
      updated = true;
    } catch (err) {
      console.warn('Failed to convert full image, was probably recently deleted');
      console.warn(imageData.full);
      imageData.full = null;
    }
  }

  if (imageData.large && imageData.large.startsWith('gs://')) {
    try {
      imageData.large = await storage.refFromURL(imageData.large).getDownloadURL();
      updated = true;
    } catch (err) {
      console.warn('Failed to convert large image, was probably recently deleted');
      console.warn(imageData.large);
      imageData.large = null;
    }
  }

  if (imageData.small && imageData.small.startsWith('gs://')) {
    try {
      imageData.small = await storage.refFromURL(imageData.small).getDownloadURL();
      updated = true;
    } catch (err) {
      console.warn('Failed to convert small image, was probably recently deleted');
      console.warn(imageData.small);
      imageData.small = null;
    }
  }

  if (updated) {
    return imageData;
  } else {
    return null;
  }
}
