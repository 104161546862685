
















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  middleware: ['authenticated'],
  transition: 'fast-fade',
  layout: 'grid',
  head: {
    title: 'Whoops, something went wrong!'
  },
  props: {
    error: Object
  },
  setup(props: any) {
    return {
      errorLabel:
        props.error.statusCode === 404 ? "Sorry, we couldn't find what you were looking for." : 'Whoops, something went wrong!',
      errorMessage:
        props.error.statusCode === 404
          ? 'The link you clicked appears to be broken.'
          : 'We are working on fixing the problem. Please try again.'
    };
  }
});
