import { render, staticRenderFns } from "./DataPage.vue?vue&type=template&id=31a19a72&scoped=true&"
import script from "./DataPage.vue?vue&type=script&lang=js&"
export * from "./DataPage.vue?vue&type=script&lang=js&"
import style0 from "./DataPage.vue?vue&type=style&index=0&id=31a19a72&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a19a72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentHeader: require('/builds/sama-apps/rainbow-care/rainbow-care-web/components/ContentHeader.vue').default,ColorButton: require('/builds/sama-apps/rainbow-care/rainbow-care-web/components/buttons/ColorButton.vue').default,Card: require('/builds/sama-apps/rainbow-care/rainbow-care-web/components/Card.vue').default})
