import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ route, store }) => {
  const navs = route.meta.map(meta => meta.nav).filter(nav => nav);

  if (store.state.lastNav === null && navs.length) {
    store.commit('setLastNav', navs[0] || null);
  }

  store.commit('setCurrentNav', navs[0] || null);

  const background = route.meta[0]?.background ? route.meta[0]?.background : 'transparent';

  store.commit('setBackground', background);
};

export default middleware;
