import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '~/store';
import { RecordState, recordState, recordMutations, recordGetters, recordActions } from './vuex-records';
import { Transaction } from '@/models/transactions';

export interface TransactionState<T extends Transaction> extends RecordState<T> {
  year: string;
}

export function transactionRecordState<T extends Transaction>(): TransactionState<T> {
  return {
    ...recordState(),
    year: new Date().getFullYear().toString()
  };
}

export function transactionRecordMutations<T extends Transaction>(): MutationTree<TransactionState<T>> {
  return {
    ...recordMutations()
  };
}

export function transactionRecordGetters<T extends Transaction>(): GetterTree<TransactionState<T>, RootState> {
  return {
    ...recordGetters(),
    total(state): number {
      return Object.values(state.records)
        .map(record => record.amount)
        .reduce((a, b) => a + b, 0);
    },
    annualTotal(state): number {
      return Object.values(state.records)
        .filter(record => record.date.startsWith(state.year))
        .map(record => record.amount)
        .reduce((a, b) => a + b, 0);
    }
  };
}

export function transactionRecordActions<T extends Transaction>(
  collection: string,
  scaledImages: string[]
): ActionTree<TransactionState<T>, RootState> {
  return {
    ...recordActions(collection, scaledImages)
  };
}
