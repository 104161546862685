import { Income } from '@/models/income';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import {
  TransactionState,
  transactionRecordState,
  transactionRecordMutations,
  transactionRecordGetters,
  transactionRecordActions
} from '~/util/vuex-transaction-records';

interface State extends TransactionState<Income> {}

export const state = (): State => ({
  ...transactionRecordState()
});

export const mutations: MutationTree<State> = {
  ...transactionRecordMutations()
};

export const getters: GetterTree<State, RootState> = {
  ...transactionRecordGetters()
};

export const actions: ActionTree<State, RootState> = {
  ...transactionRecordActions('income', [])
};
