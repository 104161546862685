import { Expense } from '@/models/expenses';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import {
  TransactionState,
  transactionRecordState,
  transactionRecordMutations,
  transactionRecordGetters,
  transactionRecordActions
} from '~/util/vuex-transaction-records';

interface State extends TransactionState<Expense> {}

export const state = (): State => ({
  ...transactionRecordState()
});

export const mutations: MutationTree<State> = {
  ...transactionRecordMutations()
};

export const getters: GetterTree<State, RootState> = {
  ...transactionRecordGetters()
};

export const actions: ActionTree<State, RootState> = {
  ...transactionRecordActions('expenses', ['receiptPhoto']),
  async uploadExpenseImage(store, { expenseId, imageFile }: { expenseId: string; imageFile: File }) {
    const extension = imageFile.name.split('.')[imageFile.name.split('.').length - 1];
    const timestamp = Date.now().toString();

    await this.$fire.firestore
      .collection(`/centers/${(store.rootState as any).centers!.centerId}/expenses`)
      .doc(expenseId)
      .update({ 'receiptPhoto.timestamp': timestamp });

    const imageRef = this.$fire.storage
      .ref()
      .child('centers')
      .child((store.rootState as any).centers.centerId!)
      .child('expenses')
      .child(expenseId)
      .child('receipt-photo.' + extension);
    await imageRef.put(imageFile, {
      contentType: imageFile.type,
      cacheControl: 'max-age=604800',
      customMetadata: {
        centerId: (store.rootState as any).centers.centerId,
        expenseId,
        timestamp
      }
    });
  },
  async deleteExpenseImage(store, expenseId: string) {
    const expense = store.state.records[expenseId];
    if (!expense) {
      return;
    }

    const deleteJobs: Promise<any>[] = [];

    if (expense.receiptPhoto.full) {
      deleteJobs.push(this.$fire.storage.refFromURL(expense.receiptPhoto.full).delete());
    }

    if (expense.receiptPhoto.small) {
      deleteJobs.push(this.$fire.storage.refFromURL(expense.receiptPhoto.small).delete());
    }

    if (expense.receiptPhoto.large) {
      deleteJobs.push(this.$fire.storage.refFromURL(expense.receiptPhoto.large).delete());
    }

    expense.receiptPhoto.full = null;
    expense.receiptPhoto.large = null;
    expense.receiptPhoto.small = null;
    deleteJobs.push(store.dispatch('update', { id: expenseId, record: expense }));

    await Promise.all(deleteJobs);
  }
};
