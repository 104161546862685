














import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    image: String,
    background: String
  },
  setup(props) {
    const style = computed(() => {
      if (!props.image) {
        return { background: props.background };
      }

      return { backgroundImage: 'url(' + props.image + ')' };
    });

    return {
      style
    };
  }
});
