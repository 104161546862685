const installPromise = new Promise(res => {
  window.addEventListener('beforeinstallprompt', e => res(e));
});

const installedPromise = new Promise(res => {
  window.addEventListener('appinstalled', () => res());
});
export default ({ store }) => {
  installPromise.then(e => {
    store.commit('registerInstallApplication', e);
  });

  installedPromise.then(() => {
    store.commit('registerInstallApplication', null);
  });
};
