import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ route, store, redirect }) => {
  if (!store.state.authenticated) {
    if (route.path === '/home' || route.path === '/home/' || route.path === '/') {
      redirect('/login');
    } else {
      redirect('/login', { redirect: route.path });
    }
  }
};

export default middleware;
