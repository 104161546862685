function calculateSecondary(providedThemeColor, element = document.getElementsByTagName('body')[0], amount = -20) {
  let themeColor = providedThemeColor;
  let usePound = false;

  if (themeColor.startsWith('var(')) {
    const sub = themeColor.slice(4, themeColor.length - 1);
    themeColor = getComputedStyle(element)
      .getPropertyValue(sub)
      .trim();
  } else if (themeColor.startsWith('--')) {
    themeColor = getComputedStyle(element)
      .getPropertyValue(themeColor)
      .trim();
  }

  if (themeColor[0] === '#') {
    themeColor = themeColor.slice(1);
    usePound = true;
  }

  const num = parseInt(themeColor, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
}

export default (_, inject) => {
  inject('calculateSecondaryColor', calculateSecondary);
};
