import 'firebase/firestore';
import { Parent } from '@/models/parents';
import { MutationTree, GetterTree, ActionTree } from 'vuex/types/index';
import { RootState } from '.';
import { RecordState, recordState, recordMutations, recordGetters, recordActions } from '~/util/vuex-records';

interface State extends RecordState<Parent> {}

export const state = (): State => ({
  ...recordState()
});

export const mutations: MutationTree<State> = {
  ...recordMutations()
};

export const getters: GetterTree<State, RootState> = {
  ...recordGetters()
};

export const actions: ActionTree<State, RootState> = {
  ...recordActions('parents', [])
};
